<template>
  <v-dialog :value="value" @input="close" max-width="800px">

    <v-card>
      <v-card-title
        class="headline grey lighten-2"
        primary-title>
        <span class="ml-3">{{$t('common.picture.dialog.title')}}</span>
      </v-card-title>

      <v-card-text>
        <v-form class="mt-6">

        <!-- Picture upload -->
          <PictureInput
            ref="pictureInput"
            width="200"
            height="200"
            margin="16"
            accept="image/jpeg,image/png,image/jpg"
            :size="5"
            :zIndex="0"
            :crop="false"
            :hideChangeButton="true"
            :removable="true"
            removeButtonClass="btn-remove"
            :custom-strings="$t('common.picture.dialog.inputs')"
            @change="onChangeUpload"
            @remove="onChangeUpload(null)"/>

      </v-form>

        <AlertBar />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>

        <v-row justify="space-around" class="my-2 mx-4">

          <!-- Cancel -->
          <v-btn @click="close(false)">{{$t('common.buttons.cancel')}}</v-btn>

          <v-spacer />

          <!-- Save -->
          <v-btn
            v-if="picture"
            :loading="isLoadingBtn.save"
            @click="customSubmit"
            class="btn-primary"
            color="primary">
            {{$t('common.buttons.save')}}
          </v-btn>
        </v-row>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  // Components
  import PictureInput from "vue-picture-input";

  // Mixins
  import dialogMixin from "@/mixins/dialog"

  export default {
    name: "ChangePictureDialog",

    components: {
      PictureInput,
      // ButtonBarFormDialog: () => import("@/components/Common/Bars/ButtonBarFormDialog"),
      AlertBar: () => import("@/components/Common/Bars/AlertBar")
    },

    mixins: [dialogMixin],

    props: {
      UPDATE_API: {type: String, default: null}
    },

    data() {
      return {

        isLoadingBtn: {
          save: false
        },
        picture: null
      }
    },

    methods: {
      onChangeUpload(value) {
        this.picture = value;
      },

      customSubmit() {
        this.isLoadingBtn.save = true;
        const formData = new FormData();
        formData.append("picture", this.$refs.pictureInput.file);

        this.$http.put(this.UPDATE_API, formData, {
          headers: {
            Authorization: "Bearer " + this.$session.get('jwt'),
            'content-type': 'multipart/form-data'
          }
        })
        .then(res => {
          this.close(true);
        })
        .catch(err => {
          this.$store.commit("alert/showError", this.$t('common.picture.errors.UPLOAD'));
        })
        .finally(() => {
          this.isLoadingBtn.save = false;
        });
      }
    }
  }
</script>

<style scoped>
.btn-remove
{
  background: transparent !important;
  font-family: MontserratMedium,serif !important;

}
</style>